<template>
  <v-container fluid>
    <TableViewComponent
      title="Table des templates de capacités par activité"
      :showAddAction="false"
      :showDeleteAction="false"
      itemLabel="activité"
      itemsLabel="activités"
      :items="entities"
      :columns="buildColumns()"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { CapacitiesService } from "@/service/etablishment/capacities_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableActivityCapacitiesTemplate",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les données. chaque élément doit avoir un id, ainsi que les fonctions edit, view, delete */
      entities: [],

      /**le service d'accès */
      capacityService: null,
      activityService: null,
    };
  },
  methods: {
    /** Chargement des données */
    async load() {
      try {
        this.loading = true;

        this.entities = [];

        // Récupération des entités de la base
        let capacities = await this.capacityService.getAll();

        // Récupération des activités
        let activities = await this.activityService.getActivities();

        activities.forEach((a) => {
          let entity = JSON.parse(JSON.stringify(a));
          entity.activityId = a.id;
          entity.activityDigitalName = a.digitalName;
          entity.activityName = a.name;

          let foundCapacities = capacities.filter((c) => c.activityId == a.id);
          entity.nbCapacities = foundCapacities.length || "-";

          entity.view = () => {
            this.$router.push(
              routes.capacities.view.root + "?activity=" + a.id
            );
          };

          //action de modification
          entity.edit = () => {
            this.$router.push(
              routes.capacities.edit.root + "?activity=" + a.id
            );
          };

          this.entities.push(entity);
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Activité - nom digital",
          align: "start",
          sortable: true,
          value: "activityDigitalName",
        },
        {
          text: "Activité - nom",
          align: "start",
          sortable: true,
          value: "activityName",
        },
        {
          text: "Nombre de capacités",
          align: "start",
          sortable: true,
          value: "nbCapacities",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditTemplateCapacities];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [RolesApplicationEnum.EditTemplateCapacities];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadTemplateCapacities];
    },
  },
  mounted() {
    //on instancie les services
    this.capacityService = new CapacitiesService(this.$api.getCapacitiesApi());
    this.activityService = new ActivityService(this.$api);

    this.load();
  },
};
</script>
